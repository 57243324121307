<template>
  <div class="el-grid">
    <div class="el-grid-nav">
      <Nav-Bar></Nav-Bar>
    </div>
    <div class="el-grid-body">
      <router-view />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    NavBar
  },
  data() {
    return {
      blockDocument: false
    };
  },
  beforeCreate() {
    let fontawsome = document.createElement("script");
    fontawsome.setAttribute("src", "https://kit.fontawesome.com/3b7fa12eaa.js");
    document.head.appendChild(fontawsome);
  },
  created() {
    this.axios.defaults.baseURL = "https://api.ftgs.io";
    this.axios.interceptors.response.use(
      function(response) {
        return response;
      },
      function(error) {
        switch (error.response.status) {
          case 401:
            console.log("unathorized access");
            alert("Unauthorized access");
            break;

          case 404:
            console.log("Page not Found");
            break;

          case 405:
            console.log("Invalid method");
            break;
        }
        //console.log("error response", error);
        return false;
      }
    );

    if (localStorage.JWT) {
      this.axios.defaults.headers.Authorization = `Bearer ${localStorage.JWT}`;
      this.axios.get("/validateToken").then(response => {
        if (response.data.isValid) {
          this.axios.defaults.headers.Authorization = `Bearer ${localStorage.JWT}`;
          this.$store.dispatch("signIn", response.data);
        } else {
          delete localStorage.JWT;
        }
      });
    }
  }, //create()
  mounted() {} //mounted()
};
</script>
<style >
.el-grid {
  display: grid;
  grid-template-rows: max-content 1fr;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
}

div.el-grid-nav div.el-grid-body {
  height: 100%;
  max-height: 100%;
  overflow: scroll;
}
</style>
