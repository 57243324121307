import { createStore } from 'vuex'
import ChamberUsers from "./modules/ChamberUsers.js"

export default createStore({
  state: {
    isLoggedIn: false,
    user: null,

  },
  mutations: {
    isLoggedIn(state, email){
      state.isLoggedIn = email;
    },

    user(state, user){
      state.user=user
    }

  },
  actions: {
    signIn({commit}, data){     
      commit('isLoggedIn', true);
      commit('user', data.user)

    }, //signIn

    signOut({commit}){
      commit('isLoggedIn', false)
      commit('user', null)
    }


  },
  modules: {
    ChamberUsers
  }
})
