import * as Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

//primevue
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card"
import Checkbox from "primevue/checkbox";
import RadioButton from "primevue/radiobutton";


import "@/assets/themes/eLuca/eLuca.scss"       //theme
import '@/assets/styles/flags.css';

//axios
import axios from "axios"
import VueAxios from "vue-axios"

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import './registerServiceWorker'

Vue.createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(VueAxios, axios)
  .use(VueSweetalert2)
  .component("Button", Button)
  .component("InputText", InputText)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("Card", Card)
  .component("Checkbox", Checkbox)
  .component("RadioButton", RadioButton)  
  .directive("tooltip", Tooltip)
  .mount("#app");
