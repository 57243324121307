import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "@/views/Dashboard/Index.vue";
import store from "@/store";

const routes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    alias: "/"
  },
  {
    name: "Certifyr",
    path: "/certifyr",
    component: () => import(/* webpackChunkName: "certifyr" */ "@/views/certifyr/Index.vue")
  },
  {
    name: "Companies",
    path: "/company",
    component: () => import(/* webpackChunkName: "company" */ "@/views/Company/Index.vue"),
    children: [
      {
        path: ":id",
        name: "Company",
        activeClass: "active",
        tag: "li",
        props: true,
        component: () => import(/* webpackChunkName: "company" */ "@/views/Company/Company.vue")
      }
    ]
  },
  {
    path: "/applications",
    name: "Applications",
    component: () => import(/* webpackChunkName: "application" */ "@/views/Application/Index.vue"),
    children: [
      {
        path: ":id",
        name: "ApplicationDetail",
        activeClass: "active",
        tag: "li",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "application" */ "@/views/Application/ApplicationDetails.vue"
          )
      }
    ]
  },
  {
    path: "/user",
    name: "User",
    component: () => import(/* webpackChunkName: "user" */ "@/views/User/Index.vue"),
    redirect: "/user/signin",
    children: [
      {
        path: "signin",
        name: "Signin",
        props: true,
        component: () => import(/* webpackChunkName: "user" */ "@/views/User/Signin.vue")
      },
      {
        path: "register",
        name: "Register",
        props: true,
        component: () => import(/* webpackChunkName: "user" */ "@/views/User/Register.vue")
      },
      {
        path: "forgotpassword",
        name: "ForgotPassword",
        props: true,
        component: () => import(/* webpackChunkName: "user" */ "@/views/User/ForgotPassword.vue")
      }
    ]
  },
  {
    name: "ChangePassword",
    path: "/user/changepassword",
    component: () => import(/* webpackChunkName: "user" */ "@/views/User/ChangePassword.vue")
  },
  {
    name: "Sandbox",
    path: "/sandbox",
    component: () => import(/* webpackChunkName: "sandbox" */ "@/views/Sandbox/Index.vue")
  },
  {
    path: "/system/log",
    name: "Log",
    component: () => import(/* webpackChunkName: "log" */ "@/views/System/Log/Index.vue")
  },
  {
    path: "/system/country",
    name: "Country",

    component: () => import(/* webpackChunkName: "country" */ "@/views/System/Country/Index.vue")
  },
  {
    path: "/system/chambers",
    name: "Chambers",

    component: () => import(/* webpackChunkName: "country" */ "@/views/System/Chambers/Index.vue")
  },
  {
    path: "/system/chamber/:id",
    name: "Chamber Detail",
    props: true,
    component: () => import(/* webpackChunkName: "country" */ "@/views/System/Chambers/Chamber.vue")
  },

  {
    path: "/system/users",
    name: "Users",

    component: () => import(/* webpackChunkName: "users" */ "@/views/System/Users/Index.vue"),
    children: [
      {
        path: ":id",
        name: "UserDetail",
        props: true,
        component: () => import(/* webpackChunkName: "users" */ "@/views/System/Users/Options.vue")
      },
      {
        path: "NewUser",
        name: "NewUser",
        component: () => import(/* webpackChunkName: "users" */ "@/views/System/Users/create.vue")
      }
    ]
  },
  {
    path: "/system/AppUsers",
    name: "AppUsers",
    component: () => import(/* webpackChunkName: "AppUsers" */ "@/views/System/AppUsers/Index.vue")
  },
  {
    path: "/reports",
    name: "Reports",
    component: () => import(/* webpackChunkName: "Reports" */ "@/views/Reports/Index.vue")
  },
  {
    path: "/remmitances",
    name: "Remmitances",
    component: () => import(/* webpackChunkName: "Remmitances" */ "@/views/Remmitances/Index.vue")
  },

   
  //Chamber routes
  {
    path: "/chamber/users",
    name: "Chamber Users",
    component: () => import(/* webpackChunkName: "chamber" */ "@/views/Chamber/Users/Index.vue")
  },
  {
    path: "/chamber/notifications",
    name: "Chamber Notifications",
    component: () => import(/* webpackChunkName: "chamber" */ "@/views/Chamber/Notifications/Index.vue")
  },
  {
    path: "/chamber/declineOptions",
    name: "Chamber Decline Options",
    component: () => import(/* webpackChunkName: "chamber" */ "@/views/Chamber/DeclineOptions/Index.vue")
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes
});

router.beforeEach((to, from, next) => {
  //console.log("From=>", from.name, "To=>", to.name)
  let unSecuredRoutes = ["Signin", "Register", "ForgotPassword", "Sandbox"];
  let isSecureRoute = unSecuredRoutes.indexOf(to.name) >= 0 ? false : true;
  if (isSecureRoute && !store.state.isLoggedIn && !localStorage.JWT) {
    next({ name: "Signin" });
    return;
  }
  next();
});

export default router;
