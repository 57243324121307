export default {
  namespaced: false,
  state: {
    users: [],
    currentUser: {name: "LA"},    
  },
  mutations: {
    SET_USERS(state, users){
      state.users=users;
    }
  },
  actions: {
    getUsers({ commit }){
      this.axios.get("/chamber/users")
      .then(response => commit('SET_USERS', response.data));

    }
  }
};
