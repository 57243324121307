<template>
  <Menubar :model="menuOptions" id="topNav" class="shadow-4">
    <template #start>
      <div id="nav-logo">
        <router-link to="/dashboard">
          <img class="inline lg:hidden logoSm" src="/assets/img/fasttrack_icon_color.svg" alt="" />
          <img class="hidden lg:inline logoLg" src="/assets/img/fasttrack_logo.svg" alt="" />
        </router-link>
      </div>
    </template>
    <template #end>
      <!-- <div class="mq-container">
        <span id="mq"></span>
      </div> -->
      <span v-if="userMenu">
        <Button
          class="p-button p-button-rounded"
          icon="fa fa-user-circle"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        />
        <Menu id="overlay_menu" ref="menu" :model="userOptions" :popup="true" />
      </span>
    </template>
  </Menubar>
</template>

<script>
import Menubar from "primevue/menubar";
import Menu from "primevue/menu";

export default {
  components: {
    Menubar,
    Menu
  },
  data() {
    return {
      menuOptions: [
        // {
        //   label: "Dashboard",
        //   icon: "fas fa-browser",
        //   visible: this.isLoggedIn,
        //   to: "/dashboard",
        // },
        {
          label: "Applications",
          icon: "fas fa-fw fa-paper-plane",
          visible: this.isLoggedIn,
          class: "link-item",
          to: "/applications"
        },
        {
          label: "Companies",
          icon: "fas fa-city ",
          class: "link-item",
          to: "/company",
          visible: this.isLoggedIn
        },
        {
          label: "Certifyr",
          icon: "fas fa-file-certificate",
          class: "link-item",
          to: "/certifyr",
          name: "Certifyr",
          visible: this.isLoggedIn
        },
        {
          label: "Tools",
          icon: "fas fa-tools",
          visible: this.isLoggedIn,
          class: "link-item",
          items: [
            {
              label: "Access Logs",
              icon: "fas fa-stream",
              to: "/system/log"
            },
            {
              label: "Application Users",
              icon: "fas fa-users",
              to: "/system/AppUsers"
            },

          ]
        },
        {
          label: "Reports",
          icon: "fas fa-chart-bar",
          visible: this.isLoggedIn,
          class: "link-item",
          items: [
            {
              label: "Remmitance Report"
            },
            {
              label: "Accounts Receivable Report"
            },
            {
              label: "Statement of Account"
            }
          ]
        },
        {
          label: "Remmitances",
          icon: "fas fa-money-check-alt",
          to: "/remmitances",
          class: "link-item",
          visible: this.isLoggedIn
        },

        {
          label: "Settings",
          icon: "fas fa-cog",
          class: "link-item",
          items: [
            {
              label: "Chamber Users",
              icon: "fas fa-users",
              to: "/chamber/users",
              visible: this.isLoggedIn
            },
            {
              label: "Notifications",
              icon: "fas fa-bells",
              to: "/chamber/notifications",
              visible: this.isLoggedIn
            },
            {
              label: "Certificate Decline Options",
              icon: "fas fa-exclamation-triangle",
              to: "/chamber/declineOptions",
              visible: this.isLoggedIn
            },

            {
              separator: true

            },
            {
              label: "Swiftdox Users",
              icon: "fas fa-users-cog",
              to: "/system/users",
              visible: this.isLoggedIn
            },
            {
              label: "Countries",
              icon: "fas fa-globe-americas",
              to: "/system/country",
              visible: this.isLoggedIn
            },
            {
              label: "Chambers of Commerce",
              icon: "fas fa-landmark",
              to: "/system/chambers",
              visible: this.isLoggedIn
            }
          ]
        }
      ],
      userOptions: [
        {
          label: "Change Password",
          icon: "fas fa-key",
          to: { name: "ChangePassword" }
        },
        {
          label: "Sign out",
          icon: "pi pi-sign-out",
          command: () => {
            this.$store.dispatch("signOut");
            delete localStorage.JWT;
            this.$router.push({ name: "Signin" });
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Sign Out",
              life: 3000
            });
          }
        }
      ] // userOptions
    };
  }, // data

  methods: {
    toggle(event) {
      this.$refs.menu.toggle(event);
    }, // toggle
    save() {
      this.$toast.add({
        severity: "success",
        summary: "Success",
        detail: "Data Saved",
        life: 3000
      });
    }, // save()
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    }
  }, // methods

  computed: {
    userMenu() {
      return this.$store.state.isLoggedIn;
    }
  } // computed
};
</script>
