<template>
<div class="limitWidthCenter">
      <div class="grid p-4 card">
        <div class="col-12">
          <h1 class="el-h4">Dashboard</h1>
        </div>
        <div class="col-12">
          <table>
            <thead>
              <tr>
                <th class="text-left">Chamber</th>
                <th>Profiles</th>
                <th class="text-center">CFS</th>
                <th class="text-center">GMP</th>
                <th class="text-center">COO</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="chamber in data" :key="chamber">
                <td>
                  {{ chamber.coc }}
                  <a
                    :href="'https://' + chamber.chamber + '.ftgs.us'"
                    target="_blank"
                    ><i class="fas fa-external-link-alt"></i></a
                  >
                </td>
                <td class="text-center profile">
                  {{ chamber.pendingProfiles }}
                </td>
                <td class="text-center  cfs">{{ chamber.cfs }}</td>
                <td class="text-center gmp">{{ chamber.gmp }}</td>
                <td class="text-center coo">{{ chamber.coo }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
      data: null,
    };
  },
  created() {
    // this.$swal("Hello Vue world!!!");

    this.axios.get('/dashboard').then((response) => {
      this.data = response.data;
    });
  }, // created()
  components: {},

  computed: {
    url(prefix) {
      return `https://${prefix}.ftgs.us`;
    },
  }, // computed
};
</script>
<style></style>
